import * as React from "react"

import Layout from "../../../components/layout/layout"
import BlogHeroSection from "../../../components/sections/blog-hero-section"
import Seo from "../../../components/seo"
import BlogPostsCategoryListSection from "../../../components/sections/blog-posts-category-list-section"

const KnowPage = () => {
  return (
    <Layout>
      <Seo 
        title="Know"
        description="Learn how to create a website with the best templates and themes you'll find here."
      />

      <BlogHeroSection />

      <BlogPostsCategoryListSection />
    </Layout>
  )
}

export default KnowPage
